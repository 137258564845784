.img-imagedb-1 {
    width: 600px ;
    height: auto;
}

.img-imagedb-2 {
    width: 700px ;
    height: auto;
}

.img-imagedb-3 {
    width: 700px ;
    height: auto;
}