.App {
  text-align: left;
  margin: 20px;
}

.aligncenter {
  text-align: center;
}

.img-app-icon {
  width: 32px ;
  height: 32px;
}

flex-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center
}

.content {
  display: block;
  min-height: 400px;
  margin: 0 auto 59px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 59px;
  margin-left: auto;
  max-width: 1180px;
  width: 97%;
}

/* Small devices (Phones, 600pxand down) -- this would display the text at 5.0rem on phones */

/* @media only screen and (max-width: 600px) {
  h1 {
      font-size: 5.0rem; 
  }
} */

/* Large devices (laptops/desktops, 992px and up) -- This would display the text at 10.0rem on laptops and larger screens */

/* @media only screen and (min-width: 992px) {
  h1 {
      font-size: 10.0rem; 
  }
} */