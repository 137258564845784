

/*
.table {
  margin: 100px;
  text-align: center;
}
*/
.SelectProgram {
  height: 35px;
}